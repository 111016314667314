/* eslint-disable */
import React from 'react'
import Link from 'gatsby-link'
import Img from "gatsby-image";
import Layout from '../components/layout'
import {graphql} from 'gatsby'
import styled from '@emotion/styled'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "../assets/css/addons.css";
import IsDesktop from '../components/common/IsDesktop';
import IsMobile from '../components/common/IsMobile';

import SEO from "../components/seo.js";
import Header from "../components/header.js";
import GeneralFooter from '../components/generalFooter';
import ImageLeftTextRight from '../paragraphs/image_left_text_right.js';
import TextLeftImageRight from '../paragraphs/text_left_image_right.js';
import ImageLeftTextRightCentered from '../paragraphs/image_left_text_right_centered.js';

import goldTexture from '../assets/gg-images/gold_texture2@2x.png'
import Slide1 from '../assets/gg-images/slider-image1@2x.jpg'
import Slide2 from '../assets/gg-images/slider-image2@2x.jpg'
import Slide3 from '../assets/gg-images/mobile-car1@2x.jpg'
import Slide4 from '../assets/gg-images/mobile-car2@2x.jpg'
import Boxes from '../assets/gg-images/boxes3@2x.png'
import Boxes1 from '../assets/gg-images/GG 2020_Cropped_Boxes_wTissue_1@2x.png'
import Boxes2 from '../assets/gg-images/GG 2020_Cropped_Boxes_wTissue_2@2x.png'
import Boxes3 from '../assets/gg-images/GG 2020_Cropped_Boxes_wTissue_3@2x.png'
import Boxes4 from '../assets/gg-images/GG 2020_Cropped_Boxes_wTissue_4@2x.png'
import Boxes5 from '../assets/gg-images/GG 2020_Cropped_Boxes_wTissue_5@2x.png'
import Boxes6 from '../assets/gg-images/GG 2020_Cropped_Boxes_wTissue_6@2x.png'
import Boxes7 from '../assets/gg-images/GG 2020_Cropped_Boxes_wTissue_7@2x.png'
import Boxes8 from '../assets/gg-images/GG 2020_Cropped_Boxes_wTissue_8@2x.png'
import MobileBoxes from '../assets/gg-images/mobile_boxes@2x.png'
import GGLogo from '../assets/gg-images/gg-logo@2x.png'
import GGRight from '../assets/gg-images/gg-right@2x.png'
import Partner1 from '../assets/gg-images/partner1@2x.png'
import Partner2 from '../assets/gg-images/partner2@2x.png'
import Partner3 from '../assets/gg-images/partner3@2x.png'
import Partner4 from '../assets/gg-images/partner4@2x.png'
import Partner5 from '../assets/gg-images/partner5@2x.png'
import TwoBoxes from '../assets/gg-images/2boxes@2x.png'
import ScottiesTs from '../assets/gg-images/scotties-tslogo@2x.png'
import ArrowDown from '../assets/gg-images/arrown-down.svg'
import Layers from '../assets/gg-images/3layers.svg'
import MobileBack from '../assets/gg-images/mobile_back@2x.png'

const TopBanner = styled.div`
    background-image: url(${goldTexture});
    padding: 30px 0;
    background-size: cover;
    text-align:center;

    display: flex;
    align-items: center;
    justify-content:center;
    p {
        color: white;
        font-family: 'museo-sans', arial;
        font-weight: 700;
        font-size: 16pt;
        line-height: 16pt;
        margin: 0 15px 0 0;
        padding: 0;
        text-align:center;
    }
    a {
        background: white;
        padding 16px 25px 15px;
        color: #000;
        font-weight: 900;
        font-family: 'HurmeGeometricSans4-Black', arial;
        border-radius: 25px;
        font-size: 11pt;
        line-height: 8pt;
        text-transform: uppercase;
    }
    @media(max-width: 1100px){
        flex-direction: column;
        a {
            margin-top: 15px;
        }
        p {
            font-size: 13pt;
            line-height: 13pt;
            margin: 0 auto;
        }
    }
`

const SliderWrapper = styled.div`
    width: 100%;
    @media(max-width: 1100px){
        background-image: url(${MobileBack});
        background-size: cover;
    }
`

const DownArrow = styled.img`
    width: 40px;
    position: absolute;
    bottom: 70px;
    margin: 0 calc(50% - 10px);
    @media(max-width: 1100px){
        display:none;
    }
`

const Slide = styled.div`
    width: 100%;
    height: 100%;
    position:relative;
    outline: none;
    background: white;
    > div {
        position: absolute;
        top: 33%;
        right: 62%;
        max-width: 400px;
        p {
            font-size: 41px;
            font-weight: 300;
            font-family: museo-sans,arial;
            color: #707070;
            letter-spacing: 3px;
        }
        .larger {
            color: #C78C08;
            font-size: 86px;
            line-height: 77px;
            font-weight: 900;
        }
        .smaller {
            font-size: 30px;
            text-transform: uppercase;
            margin-bottom: 25px;
        }
        .regular {
            text-align: center;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0;
        }
        .smallup {
            text-align: center;
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 25px;
            margin-top: 40px;
        }
        img {
            margin: 0 auto;
        }
    }
    img {
        margin-left: auto;
    }
    @media(max-width: 1100px){
        padding-bottom: 50px;
        background:transparent;
        > div {
            position:relative;
            right: 0;
            margin: 150px auto 50px;
            max-width: 94%;
            text-align:center;
            p {
                font-size: 30px;
                font-weight: 300;
                font-family: museo-sans,arial;
                color: #707070;
                letter-spacing: 3px;
            }
            .larger {
                color: #C78C08;
                font-size: 64px;
                line-height: 65px;
                font-weight: 900;
            }
            .smaller {
                font-size: 21.5px;
            }
            .regular {
                text-align: center;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0;
            }
            .smallup {
                text-align: center;
                font-size: 12px;
                text-transform: uppercase;
                margin-bottom: 25px;
                margin-top: 40px;
            }

        }
        img {
          width: 100%;
          max-width: 500px;
          margin: 0 auto !important;
        }
    }
`

const BoxesWrapper = styled.div`
    padding: 75px 10%;
    @media(max-width: 1100px){
        padding: 50px 3%;
    }
`

const BottomWrapper = styled.div`
    padding: 0px 0% 25px 5%;
    display:flex;
    align-items:center;
    > div {
        width: 30%;
        text-align: center;
        > p {
            color: #707070;
            font-family: museo-sans, arial;
            font-size: 22px;
            line-height: 30px;
            margin: 30px auto 70px;
            max-width: 450px;

        }
        > img {
            width: 200px;
        }
        > a {
            color: #C78C08;
            border: 2px solid #C78C08;
            padding: 15px 45px;
            border-radius: 25px;
            &:hover {
                border: 3px solid #C78C08;
            }
        }
    }
    > img {
        width: 70%;
    }
    @media(max-width: 1100px){
        flex-direction: column;
        padding: 0;
        > div {
            width: 94%;
            margin: 0 auto;
             > p {
                 max-width: 100%;
             }
        }
        > img {
            width: 100%;
            margin: 50px 0;
        }
    }
`

const FlexContainer = styled.div`
    display: flex;
    margin-bottom: 100px;
    > div {
        width: 50%;
        text-align: center;
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        .logo {
            width: 70%;
            margin: 70px auto;
        }
        p {
            font-size: 26px;
            line-height: 36px;
            text-align:center;
            margin: 0 auto;
            max-width: 450px;
            font-family: museo-sans;
            span {
                font-weight: 700;
            }
        }
    }
    @media(max-width: 1100px){
        flex-direction: column;
        > div {
            width: 100%;
            .logo {
                width: 100%;
                max-width: 400px;
                margin: 70px auto;
            }
            p {
                max-width: 96%;
                font-size: 20px;
                line-height: 36px;
            }
        }
    }
`

const LogoContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    align-items: center;
    margin: 45px auto 0;
    max-width: 450px;
    div {
        width: 33%;
        margin: 10px 0 0;
        img {
            width: 130px;
            height: auto !important;
        }

    }
    div:nth-child(3){
        width: 33%;
        img{
            width: 70px !important;
            height: auto !important;
        }
    }
`

const BoxesContainer = styled.div`
  display:flex;
  flex-wrap: wrap;
  text-align:center;
  justify-content:space-around;
  img {
      width: 33%;
  }
  img:nth-child(4){
    width: 35%;
    margin: 0 0 0 auto;
  }
  img:nth-child(5){
    width: 35%;
    margin: 0 auto 0 0;
  }
  @media(max-width: 1100px){
    max-width: 500px;
    margin: 0 auto;
    img, img:nth-child(4), img:nth-child(5) {
        width: 50%;
    }
  }
`

class Holiday extends React.Component {
    constructor(props) {
      super(props);
      this.state = {}
    }
    render() {
        var settings = {
          dots: true,
          arrows: false,
          infinite: true,
          speed: 800,
          autoplaySpeed: 3000,
          slidesToShow: 1,
          draggable: true,
          autoplay: true,
          fade: true,
        };
        const data = this.props.data.nodePage
        const retailers = this.props.data.allNodeStore.edges
        var paragraphs = data.relationships.field_paragraph
        paragraphs[1].relationships.field_image.localFile.childImageSharp.sizes.src = GGRight
        var bch = {
          edges: []
        }
        const ids = ['95283372-ab91-5a90-8172-5fe9bdebc3c2','96b51589-27db-5535-8d4c-e4271bea3278','fb40903f-7382-5c94-b388-17caafc054d4']
        for (var i = 0; i < this.props.data.allNodeBlog.edges.length; i++) {
          if(ids.indexOf(this.props.data.allNodeBlog.edges[i].node.id) != -1){
            bch.edges.push(this.props.data.allNodeBlog.edges[i])
          }
        }
        return (
            <Layout>
              <div id="gg-promo" className="page-wrapper">
                <SEO meta={data.field_meta_tags}/>
                <TopBanner>
                    <p>Save on Scotties Triple Soft<sup>™</sup></p>
                    <a target="_blank" href="../ggcoupon">Get Coupon</a>
                </TopBanner>
                <main style={{position:'relative'}}>
                    <h1 className="sr-only">Genevieve Gorder Collection</h1>
                    <Header tripleSoft float={true} retailers={retailers} hideBuy={true}></Header>
                    <SliderWrapper>
                        <IsDesktop>
                            <Slider {...settings}>
                                <Slide>
                                    <div>
                                        <p>8 EXCLUSIVE NEW</p>
                                        <p className="larger">DESIGNS</p>
                                        <p className="smaller">By Genevieve Gorder</p>
                                        <img src={Layers} alt="3 layers of softness for Ultimate Protection" />
                                    </div>
                                    <img src={Slide1} alt="" />
                                </Slide>
                                <Slide>
                                    <div>
                                        <p className="regular">Your home is your sanctuary. That’s why we teamed up with renowned interior designer Genevieve Gorder to bring you eight custom winter-themed boxes. Scotties Triple Soft 3-Ply & Triple Soft with Aloe tissues bring the ultimate comfort and protection for your family.</p>
                                        <p className="smallup">Genevieve Gorder, celebrity interior designer and television personality. </p>
                                    </div>
                                    <img src={Slide2} alt="" />
                                </Slide>
                            </Slider>
                        </IsDesktop>
                        <IsMobile>
                            <Slider {...settings}>
                                <Slide>
                                    <div>
                                        <p>8 EXCLUSIVE NEW</p>
                                        <p className="larger">DESIGNS</p>
                                        <p className="smaller">By Genevieve Gorder</p>
                                        <img src={Layers} alt="3 layers of softness for Ultimate Protection" />
                                    </div>
                                    <img src={Slide3} alt=""  />
                                </Slide>
                                <Slide>
                                    <div>
                                        <p className="regular">Your home is your sanctuary. That’s why we teamed up with renowned interior designer Genevieve Gorder to bring you eight custom winter-themed boxes. Scotties Triple Soft 3-Ply & Triple Soft with Aloe tissues bring the ultimate comfort and protection for your family.</p>
                                        <p className="smallup">Genevieve Gorder, celebrity interior designer and television personality. </p>
                                    </div>
                                    <img src={Slide4} alt="" />
                                </Slide>
                            </Slider>
                        </IsMobile>

                        <DownArrow id="down-arrow-bounce" src={ArrowDown} />
                    </SliderWrapper>
                </main>
                <BoxesWrapper>
                    <FlexContainer>
                        <div>
                            <BoxesContainer>
                                <img src={Boxes1} alt="" />
                                <img src={Boxes2} alt="" />
                                <img src={Boxes3} alt="" />
                                <img src={Boxes4} alt="" />
                                <img src={Boxes5} alt="" />
                                <img src={Boxes6} alt="" />
                                <img src={Boxes7} alt="" />
                                <img src={Boxes8} alt="" />
                            </BoxesContainer>
                        </div>
                        <div>
                            <img className="logo" src={GGLogo} alt="Genevieve Gorder Collection" />
                            <p> Find these eight <span>EXCLUSIVE</span> Genevieve Gorder custom designed <span>Scotties Triple Soft<sup>™</sup></span> and <span>Triple Soft Aloe</span> boxes at one of these retailers while supplies last:</p>
                            <LogoContainer>
                                <div><a target="_blank" href="https://www.shopmarketbasket.com/"><img src={Partner1} alt="Shop Market Basket" /></a></div>
                                <div><a target="_blank" href="https://www.publix.com/"><img src={Partner2} alt="Publix" /></a></div>
                                <div><a target="_blank" href="https://www.hannaford.com/"><img src={Partner3} alt="Hanna Ford" /></a></div>
                                <div><a target="_blank" href="https://www.dollartree.com/"><img src={Partner4} alt="Dollar Tree" /></a></div>
                                <div><a target="_blank" href="https://www.walmart.com/"><img src={Partner5} alt="Walmart" /></a></div>
                            </LogoContainer>
                            <a className="gold-button" target="_blank" href="../../ggcoupon">Get Coupon</a>
                        </div>
                    </FlexContainer>
                </BoxesWrapper>
                <div id="gg-promo">
                    {paragraphs.map(paragraph => {
                      switch (paragraph.__typename) {
                        case 'paragraph__text_left_image_right':
                          return (
                            <TextLeftImageRight key={paragraph.id} data={paragraph} blogs={bch} />
                          );
                          break;
                      }
                    })}
                </div>
                <BottomWrapper>
                    <div>
                        <img src={ScottiesTs} alt="Scotties triple soft - comfort by design" />
                        <p>3 layers of softness for your family care with the ultimate strength and protection for your toughest sneezes, blows, or nasty colds. Packaged in designs to suit a range of styles. </p>
                        <Link to="/products/triple-soft">Triple Soft</Link>
                    </div>
                    <img src={TwoBoxes} alt="" />
                </BottomWrapper>
              </div>
              <div className="gold-footer">
                  <GeneralFooter/>
              </div>
            </Layout>
        )
    }
}

export default Holiday

export const query = graphql `
query Holiday {
  nodePage(uuid: {eq: "98fd3607-6ed6-4106-877d-2d234056b543"}) {
    id
    title
    field_meta_tags
    relationships {
      field_paragraph {
        __typename
        ... on paragraph__image_left_text_right {
          id
          field_title
          field_sub_title
          field_body_text {
            value
          },
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 700) {
                    src
                  }
                }
              }
            }
          }
        }
        ... on paragraph__text_left_image_right {
          id,
          field_title
          field_sub_title
          field_body_text {
            value
          },
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 700) {
                    src
                  }
                }
              }
            }
          }
        }
        ... on paragraph__image_left_text_right_centered {
          id,
          field_title
          field_sub_title
          field_body_text {
            value
          },
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 550) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  allNodeBlog(limit:10, sort:{fields:[created], order: DESC}) {
    edges {
      node {
        id
        title
        created(formatString: "MMM DD YYYY")
        field_author
        path {
          alias
        }
      }
    }
  }
  allNodeStore {
    edges {
      node {
        id
        title
        relationships {
          field_image {
            localFile {
              childImageSharp {
                resolutions(width: 143) {
                  src
                }
              }
            }
          }
          field_state {
            name
          }
        }
      }
    }
  }
}
`
