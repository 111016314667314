import styled from '@emotion/styled'

const IsMobile =  styled.div`
  display: none;

  @media screen and (max-width:919px) {
    display: block;
  }
`

export default IsMobile
