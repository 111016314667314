import styled from '@emotion/styled'

const IsDesktop =  styled.div`
  display: block;

  @media screen and (max-width:919px) {
    display: none;
  }
`

export default IsDesktop
